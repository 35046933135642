import type {
  DtStatus,
  State,
  SummaryState,
  TimelineSectionType,
} from '@motional-cc/fe/interface/api/api-concierge';
import type {
  Environment,
  JobSubtype,
  JobType,
  MaintenanceJob,
  PropName,
  RideshareJob,
  RoadType,
} from '@motional-cc/fe/interface/api/kamaji';
import type {
  Model as CarModelName,
  Gen2BundleSupportedPlatform,
  Gen2Platform,
  ModernModel as ModernCarModelName,
  OneTouchStepStatus,
  Region,
  TestPlatform,
} from '@motional-cc/fe/interface/api/registrar';
import type { Immutable, RequiredKeys } from 'src/interface/utility';
import {
  FpdDisplayEventPriority,
  PendingCommandState,
  RejectedCommandState,
} from '@motional-cc/fe/interface/api/api-cerebro';
import assertArrayExhaustsType from 'src/tools/assertArrayExhaustsType';
import { BenignAlertLevel, CarMode, FullJobBody } from './unsorted-types';

export const VEHICLE_STATES = assertArrayExhaustsType<State>()([
  'Unavailable',
  'Available',
  'WaitingForOnboardResponse',
  'WaitingForDispatchProceed',
  'EnrouteToGoal',
  'ArrivedAtGoal',
]);

export const VEHICLE_SUMMARY_STATES = assertArrayExhaustsType<SummaryState>()([
  'ON_RIDE',
  'ON_DISPATCH',
  'ON_MISSION',
  'ONLINE',
  'PARTIALLY_ONLINE',
  'DEPLOYMENT_PENDING',
  'DEPLOYMENT_IN_PROGRESS',
  'DEPLOYMENT_FAILED',
  'DEPLOYMENT_MISSING',
  'OFFLINE',
  'REGISTRATION_REVOKED',
  'OUT_OF_SERVICE',
  'UNKNOWN',
]);

export const CAR_MODES = assertArrayExhaustsType<CarMode>()([
  'Unknown',
  'Autonomous',
  'Manual',
]);

// Vehicles are ordered as most recent first
export const MODERN_CAR_MODEL_NAMES =
  assertArrayExhaustsType<ModernCarModelName>()([
    'Ioniq',
    'BMW',
    'Pacifica',
    'Eval',
  ]);
export const CAR_MODEL_NAMES = assertArrayExhaustsType<CarModelName>()([
  'Ioniq',
  'BMW',
  'Pacifica',
  'Eval',
  'Audi',
  'Zoe',
]);

export const REGIONS = assertArrayExhaustsType<Region>()([
  'las_vegas',
  'pittsburgh',
  'singapore',
  'boston',
  'santa_monica',
  'san_diego',
  'austin',
]);

const GEN2_PLATFORMS = assertArrayExhaustsType<Gen2Platform>()([
  'GEN2_TCar_B1',
  'GEN2_TCar_B1+',
  'GEN2_AVProto',
  'GEN2_AVProto_V2',
  'GEN2_AVPilot',
  'GEN2_AVPilot_V2',
  'GEN2_AVProd',
]);

const GEN3_PLATFORMS = assertArrayExhaustsType<TestPlatform>()(['GEN3_Ampere']);

export const GEN2_BUNDLE_SUPPORTED_PLATFORMS =
  assertArrayExhaustsType<Gen2BundleSupportedPlatform>()([
    ...GEN2_PLATFORMS,
    ...GEN3_PLATFORMS,
  ]);

export const ROAD_TYPES = assertArrayExhaustsType<RoadType>()([
  'test track',
  'public road',
  'mix',
]);

export const ENVIRONMENTS = assertArrayExhaustsType<Environment>()([
  'day',
  'night',
  'precipitation',
  'cloudy',
]);

export const JOB_TYPES = assertArrayExhaustsType<JobType>()([
  'rideshare',
  'maintenance',
  'dev test',
  'public road evaluation',
  'manual data collection',
  'mapping',
  'vehicle integration',
  'demo',
  'training',
]);

export const JOB_SUBTYPES: {
  [jobType in JobType | 'other']: Immutable<JobSubtype[]>;
} = {
  rideshare: ['lyft', 'uber eats'],
  maintenance: ['vehicle intake', 'vehicle outtake'],
  'dev test': ['daily master', 'specific build'],
  'public road evaluation': ['mileage accumulation'],
  'manual data collection': ['machine learning training', 'resimulation'],
  mapping: [
    'quality assurance',
    'data collection',
    'manual validation',
    'auto validation',
    'scouting mission',
  ],
  'vehicle integration': [
    'dbw update',
    'sensor calibration',
    'sensor validation',
    'software update',
    'component update',
    'troubleshooting',
  ],
  demo: [
    'investor',
    'government official',
    'golden rider',
    'press',
    'internal',
    'photography session',
    'dry run',
  ],
  training: ['new hire', 'recertification'],
  other: assertArrayExhaustsType<JobSubtype>()([
    'lyft',
    'uber eats',
    'vehicle intake',
    'vehicle outtake',
    'daily master',
    'specific build',
    'mileage accumulation',
    'machine learning training',
    'resimulation',
    'quality assurance',
    'data collection',
    'manual validation',
    'auto validation',
    'scouting mission',
    'dbw update',
    'sensor calibration',
    'sensor validation',
    'software update',
    'component update',
    'troubleshooting',
    'investor',
    'government official',
    'golden rider',
    'press',
    'internal',
    'photography session',
    'dry run',
    'new hire',
    'recertification',
  ]),
} as const;

export const REQUIRED_MAINTENENCE_PROPS = assertArrayExhaustsType<
  RequiredKeys<MaintenanceJob>
>()([
  'region',
  'type',
  'subtype',
  'starts_at',
  'ends_at',
  'vehicles',
  'notes',
  'requestor_must_be_present',
  'ticket',
]);

export const REQUIRED_OTHER_PROPS = assertArrayExhaustsType<
  RequiredKeys<FullJobBody>
>()(['region', 'type', 'subtype', 'starts_at', 'ends_at', 'vehicles', 'notes']);

export const PROP_NAMES = assertArrayExhaustsType<PropName>()([
  'mannequin',
  'pedestrian',
  'bicycle',
  'large vehicle',
  'traffic light',
  'stroller',
  'cone',
]);

export const TIMELINE_SECTION_TYPES =
  assertArrayExhaustsType<TimelineSectionType>()(['rva', 'ride']);

export const ONE_TOUCH_STEP_STATUSES =
  assertArrayExhaustsType<OneTouchStepStatus>()([
    'pass',
    'fail',
    'error',
    'in_progress',
    'awaiting',
    'unknown',
    'timeout',
  ]);
// TODO: consider moving this into the swagger
export const FAILED_ONE_TOUCH_STEP_STATUSES: ReadonlyArray<OneTouchStepStatus> =
  ['error', 'fail', 'timeout'];
export const COMPLETED_ONE_TOUCH_STEP_STATUSES: ReadonlyArray<OneTouchStepStatus> =
  ['pass', ...FAILED_ONE_TOUCH_STEP_STATUSES];
export const INACTIVE_ONE_TOUCH_STEP_STATUSES: ReadonlyArray<OneTouchStepStatus> =
  ['awaiting', ...COMPLETED_ONE_TOUCH_STEP_STATUSES];

export const BENIGN_ALERT_LEVELS = assertArrayExhaustsType<BenignAlertLevel>()([
  'success',
  'info',
]);

export const REQUIRED_RIDESHARE_PROPS = assertArrayExhaustsType<
  RequiredKeys<RideshareJob>
>()(['region', 'type', 'subtype', 'starts_at', 'ends_at', 'vehicles']);

export const REJECTED_COMMAND_STATES =
  assertArrayExhaustsType<RejectedCommandState>()([
    'Rejected',
    'Expired',
    'Failed',
  ]);

export const PENDING_COMMAND_STATES =
  assertArrayExhaustsType<PendingCommandState>()([
    'Requested',
    'Acknowledged',
    'Running',
    'TimedOut',
  ]);

export const MESSAGE_PRIORITY_LEVELS =
  assertArrayExhaustsType<FpdDisplayEventPriority>()(['High', 'Medium', 'Low']);

export const DT_STATUSES = assertArrayExhaustsType<DtStatus>()([
  'Cancelled',
  'Done',
  'In Setup',
  'In Test',
  'In Triage',
  'On Hold',
  'Ready For Execution',
  'Ready For Triage',
  'Ready For Vo',
  'Scheduled',
  'Transit To Track',
  'Writing Results',
]);
