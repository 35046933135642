const listFormatters: Partial<{
  [ListFormatType in Intl.ListFormatType]: Intl.ListFormat | false;
}> = {
  conjunction:
    !!(Intl.ListFormat && navigator.language) &&
    new Intl.ListFormat(navigator.language, { type: 'conjunction' }),

  disjunction:
    !!(Intl.ListFormat && navigator.language) &&
    new Intl.ListFormat(navigator.language, { type: 'disjunction' }),
};

export const formatList = (
  arr: ReadonlyArray<string>,
  { type = 'conjunction' }: { type?: Intl.ListFormatType } = {},
) => {
  const listFormatter = listFormatters[type];
  return listFormatter ? listFormatter.format(arr) : arr.join(', ');
};

export const formatListWithOr = (arr: ReadonlyArray<string>) =>
  formatList(arr, { type: 'disjunction' });
