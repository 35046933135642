type ErrorsList = Array<{
  loc: Array<string | number>;
  msg: string;
  type: string;
  [meta: string]: string | Array<string | number>;
}>;

export class ApiError extends TypeError {
  name = 'ApiError';
  status: number;
  code?: string;
  errors?: ErrorsList;

  constructor(
    status: number,
    message: string,
    code?: string,
    errors?: ErrorsList,
  ) {
    super(message);

    this.status = status;
    this.code = code;
    this.errors = errors;
  }
}
